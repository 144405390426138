import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home.js';
import Skincare from './Skincare.js';

// import Present from './Present';
// import Book from './Book';
// import Supplement from './Supplement';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/skincare" element={<Skincare />} />
        {/* <Route path="/present" element={<Present/>} />
        <Route path="/book" element={<Book/>} />
        <Route path="/supplement" element={<Supplement/>} /> */}
      </Routes>
    </Router>
  );
}

export default App;
