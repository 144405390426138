import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

function Home() {
  const [category, setCategory] = useState('');
  const navigate = useNavigate();

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    if (event.target.value) {
      navigate(`/${event.target.value}`);
    }
  };

  return (
    <div className="home-container">
      <h1>Alice Recommendation AI</h1>
      <div className="header-container">
        <img
          src="/beautifulwoman.png"
          alt="skincare-products-recommendation-alice-ai"
        />
        <h2>
          Skincare Recommendation <br />
          for Your Unique Skin Type
        </h2>
        <h1>Based on User Reviews!</h1>
        <p>
          You can make confident choices <br />
          knowing that others with similar skin concerns <br />
          have had success with these products.
        </p>
      </div>
      {/* <div className="form-container">
        <h2>Get Personalized Recommendation</h2>
      </div> */}
      <div className="category-select">
        <h2>Get Personalized Recommendation</h2>
        <select
          value={category}
          onChange={handleCategoryChange}
          className="dropdown">
          <option value="" disabled>
            Select a category
          </option>
          <option value="skincare">Skincare</option>
          {/* <option value="present">Present</option>
          <option value="book">Book</option>
          <option value="supplement">Nutritional Supplement</option> */}
        </select>
      </div>
      <div className="div-container">
        <div className="div-container-first">
          <h3>Personalized Recommendations</h3>
          <p>
            <strong>"No More Guesswork"</strong>: We offer insights from users
            who have skin just like yours. Find products tailored to your unique
            needs.
          </p>
          <p>
            <strong>"Your Perfect Match"</strong>: Our AI analyzes thousands of
            reviews to find products that are proven to work for your specific
            skin type.
          </p>
        </div>
        <div className="div-container-second">
          <h3>Trust in Real Experiences</h3>
          <p>
            <strong>"Learn from Others"</strong>: See which products are highly
            recommended by people with the same skin type and issues as you.
          </p>
          <p>
            <strong>"User-Verified Choices"</strong>: Get recommendations based
            on actual experiences and results, not just marketing promises.
          </p>
        </div>
      </div>
      <footer>
        <a href="privacy.html">Privacy Policy</a>
        <a href="terms.html">Terms and Conditions</a>
      </footer>
    </div>
  );
}

export default Home;
