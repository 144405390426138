import React, { useState } from 'react';
import './Skincare.css';
import config from './config.js'; // Import the configuration

function Skincare() {
  const [userMessage, setUserMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([
    {
      sender: 'bot',
      text: 'Welcome to the Alice Recommendation AI. I am Alice who will give you skincare recommendations. Please select your skin type and country.',
      isInitial: true,
    },
  ]);

  const [skinType, setSkinType] = useState('');
  const [country, setCountry] = useState('');
  const [initialSelectionDone, setInitialSelectionDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    setUserMessage(event.target.value);
  };
  const handleInitialSelection = async (selectedSkinType, selectedCountry) => {
    setInitialSelectionDone(true);
    setChatMessages((prevMessages) => [
      ...prevMessages,
      {
        sender: 'user',
        text: `Please recommend the best skincare products for ${selectedSkinType} skin.`,
      },
    ]);
    setIsLoading(true);

    setChatMessages((prevMessages) => [
      ...prevMessages,
      {
        sender: 'bot',
        text: 'Please have some tea while I prepare for the recommendation.',
      },
    ]);

    // Make an API call to the backend
    try {
      const response = await fetch(`${config.apiUrl}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          skinType: selectedSkinType,
          country: selectedCountry,
          userMessage: '',
        }),
      });

      const data = await response.json();
      const botResponse = data.assistant;

      setChatMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { sender: 'bot', text: botResponse, isRecommendation: true },
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setChatMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { sender: 'bot', text: 'Sorry, something went wrong.' },
      ]);
      setIsLoading(false);
    }
  };

  const handleSkinTypeChange = (event) => {
    setSkinType(event.target.value);
    if (country) {
      handleInitialSelection(event.target.value, country);
    }
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    if (skinType) {
      handleInitialSelection(skinType, event.target.value);
    }
  };

  const handleSendMessage = async (message = null) => {
    const userText = message || userMessage;
    if (userText.trim() === '') return;

    setChatMessages((prevMessages) => [
      ...prevMessages,
      { sender: 'user', text: userText },
    ]);
    setIsLoading(true);

    setChatMessages((prevMessages) => [
      ...prevMessages,
      {
        sender: 'bot',
        text: 'Please give me a little more time for the recommendation...',
      },
    ]);

    // Make an API call to the backend
    try {
      const response = await fetch(`${config.apiUrl}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          skinType: skinType,
          country: country,
          userMessage: userText,
        }),
      });

      const data = await response.json();
      const botResponse = data.assistant;

      setChatMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { sender: 'bot', text: botResponse, isRecommendation: true },
      ]);
      setUserMessage('');
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setChatMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { sender: 'bot', text: 'Sorry, something went wrong.' },
      ]);
      setIsLoading(false);
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h2>Alice Skincare Recommendation</h2>
      </div>
      <div className="chat-body">
        {chatMessages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            <div className="profile-text">
              {message.sender === 'bot' && (
                <img
                  src="/beautifulwoman.png"
                  alt="Bot Profile"
                  className="profile-pic"
                />
              )}
              <div dangerouslySetInnerHTML={{ __html: message.text }} />
            </div>

            {message.isInitial && (
              <div className="dropdown-container">
                <p>
                  {' '}
                  <select
                    value={skinType}
                    onChange={handleSkinTypeChange}
                    className="dropdown">
                    <option value="" disabled>
                      Select your skin type
                    </option>
                    <option value="Oily">Oily</option>
                    <option value="Dry">Dry</option>
                    <option value="Combination">Combination</option>
                    <option value="Acne-prone">Acne-prone</option>
                    <option value="Normal">Normal</option>
                  </select>
                </p>
                <p>
                  {' '}
                  <select
                    value={country}
                    onChange={handleCountryChange}
                    className="dropdown">
                    <option value="" disabled>
                      Select your country
                    </option>
                    <option value="North and South America">
                      North and South America
                    </option>
                    <option value="Asia">Asia</option>
                    <option value="Others">Others</option>
                  </select>
                </p>
              </div>
            )}
            {message.isRecommendation && (
              <div className="more-suggestions-button-container">
                <button
                  className="more-suggestions-button"
                  onClick={() => handleSendMessage('Recommend another product')}
                  disabled={isLoading}>
                  Another Recommendation
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="chat-footer">
        <input
          type="text"
          value={userMessage}
          onChange={handleInputChange}
          placeholder="Type your own question here..."
          disabled={!initialSelectionDone || isLoading}
        />
        <button
          onClick={() => handleSendMessage()}
          disabled={!initialSelectionDone || isLoading}>
          Recommend to me
        </button>
      </div>
    </div>
  );
}

export default Skincare;
